<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoRegioes"
      :loading="loading"
      :paginate="true"
      sort-by="regiao"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Regiões</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer> </v-spacer>
          <RegisterBottom
            v-can-access="181"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.regiao`]="{ item }">
        {{ item.regiao | TitleCase }} </template
      ><template v-slot:[`item.acoes`]="{ item }">
      <!--  <v-btn
          icon
          v-can-access="182"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
         <v-btn
          icon
          v-can-access="1705"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        -->
        <IconBottom
        :name="'edit'"
        @click="editItem(item)"
         v-can-access="182"
        />
        <IconBottom
        :name="'delete'"
        v-can-access="1705"
        @click="deleteItem(item)"
        />
       
      </template>
    </BaseTable>
    <ModalCadRegioes
      :dialog="dialog"
      :labelBtn="labelBtn"
      :regioes="regioes"
      @close="refresh()"
    />
  </div>
</template>

<script>
import sistema from "@/services/http/sistemaService";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import ModalCadRegioes from "@/components/empresa/regioes/ModalCadRegioes";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import IconBottom from "@/components/shared/bottons/IconBottom";

export default {
  name: "TabelaRegiao",

  components: {
    BaseTable,
    ModalCadRegioes,
    RegisterBottom,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      headers: [
        { text: "ID", value: "id_regiao" },
        { text: "Regiao", value: "regiao" },
        { text: "Ações", value: "acoes", align: "center" },
      ],
      infoRegioes: [],
      regioes: {},
    };
  },

  methods: {
    async fetchRegion() {
      this.loading = true;
      const { data } = await sistema().regioes().show({ per_page: -1 });
      this.infoRegioes = data.data;
      this.loading = false;
    },
    editItem(item) {
      this.regioes = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.fetchRegion();
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deletar Regiões`,
          ` Deseja deletar Regiões ${item.regiao} ?`
        );
        sistema().regioes(item.id_regiao).delete(
          {},
          {
            notification: true,
            text: "Regiões deletada com sucesso!",
          }
        );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Regiões!",
        });
      } finally {
        this.fetchRegion();
      }
    },
  },

  mounted() {
    this.fetchRegion();
  },
};
</script>

<style></style>
