<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar" ? "Editar Regioes" : "Cadastrar "
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
          <v-col v-if = " labelBtn == 'Salvar' " >
              <v-text-field
                label="ID"
                v-model="form.id_regiao"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Regiões"
                v-model="form.regiao"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editar() : cadastrar()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";
export default {
  name: "ModalCadRegioes",

  mixins: [rules],
  components: {
  
  },

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    regioes: {
      type: Object
    }
  },

  data() {
    return {
      form: {},
    };
  },

  watch: {
    regioes() {
      if (this.regioes) {
        this.form = this.regioes;
      }
    }
  },

  methods: {
    async cadastrar() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .regioes()
          .store(this.form, {
            notification: true,
            message: "Regiões cadastrada com sucesso!"
          }),
          this.close();
      }
    },

    async editar() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await sistema()
          .regioes(this.form.id_regiao)
          .update(this.form, {
            notification: true,
            message: "Regiões editado com sucesso!"
          }),
        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
